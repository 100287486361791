import {AppBar, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import NotesIcon from '@mui/icons-material/Notes';
import MenuIcon from '@mui/icons-material/Menu';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {Box} from '@mui/system';
import {useIsMobile} from '@src/hooks/useIsMobile';
import React, {useEffect, useMemo, useState} from 'react';
import {Outlet, useMatch, useNavigate} from 'react-router-dom';
import logo from '@assets/imgs/logo.svg';
import {routes} from '@src/utils/routes';
import {useTranslation} from 'react-i18next';
import CredentialsHelper from '@src/utils/credentialsHelper';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {useGetMeQuery} from '@src/modules/admins/admins.api';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cx from 'classnames';

import UserInfo from '../UserInfo';

import styles from './scaffolding.module.scss';

export default function Scaffolding() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const isMobile = useIsMobile();

    return (
        <Box display='flex' height='100vh'>
            <CustomAppBar isMobile={isMobile} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            <CustomDrawer isMobile={isMobile} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            <Box display='flex' flexDirection='column' flexGrow='1'>
                <Toolbar />
                <Box className={styles.content}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

const CustomAppBar = ({isMobile, setOpenDrawer, openDrawer}) => {
    return (
        <AppBar
            position='fixed'
            className={styles.customDrawer}
            sx={{zIndex: 2}}
        >
            <Toolbar>
                <img src={logo}></img>
                <Box flexGrow='1' />
                <AppBarRight isMobile={isMobile} onClick={() => setOpenDrawer(!openDrawer)} />
            </Toolbar>
        </AppBar>
    );
};

const CustomDrawer = ({isMobile, openDrawer, setOpenDrawer}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isRootPath = !!useMatch(routes.ROOT);
    const {data: loggedUser} = useGetMeQuery();

    const handleDrawerClick = () => {
        setOpenDrawer(prev => !prev);
    };
    const DrawerItems = useMemo(() => {
        return [
            {
                path: routes.ACCOUNTS,
                title: t('ACCOUNTS'),
                icon: <PeopleIcon />,
                allowed: loggedUser?.permissions.commercialAccounts,
            },
            {
                path: routes.DEMO_ACCOUNTS,
                title: t('DEMO_ACCOUNTS'),
                icon: <MenuBookIcon />,
                allowed: loggedUser?.permissions.trainingAccounts,
            },
            {
                path: routes.FAQ,
                title: t('FAQ.NAME'),
                icon: <LiveHelpIcon />,
                allowed: loggedUser?.permissions.faq,
            },
            {
                path: routes.SUGGESTIONS,
                title: t('SUGGESTIONS'),
                icon: <NotesIcon />,
                allowed: loggedUser?.permissions.notes,
            },
            {
                path: routes.TUTORIALS,
                title: t('TUTORIALS'),
                icon: <PlayCircleOutlineIcon />,
                allowed: loggedUser?.permissions.tutorials,
            },
            {
                path: routes.TRAININGS,
                title: t('TRAININGS'),
                icon: <OndemandVideoIcon />,
                allowed: loggedUser?.permissions.trainings,
            },
            {
                path: routes.ADMINS,
                title: t('ADMINS.ADMINS'),
                icon: <AccountBoxIcon />,
                allowed: loggedUser?.permissions.admins,
            },
        ].filter(i => i.allowed);
    }, [t, loggedUser]);

    useEffect(() => {
        if (isRootPath && DrawerItems.length > 0) {
            const firstDrawerItem = DrawerItems.find(drawerItem => drawerItem.allowed);
            navigate(firstDrawerItem.path);
        }
    }, [isRootPath, DrawerItems]);

    const handleLogout = () => {
        CredentialsHelper.clear();
        navigate(routes.LOGIN);
    };

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={openDrawer}
            onClose={setOpenDrawer}
            ModalProps={{keepMounted: true}}
            className={cx(styles.appDrawer, {
                [styles.open]: openDrawer,
                [styles.close]: !openDrawer,
            })}
          >
            <div className={styles.drawerHeader}>
                <IconButton onClick={handleDrawerClick}>
                    {openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <div className={styles.appDrawerContent}>
                {isMobile && <UserInfo isMobile={isMobile} />}
                <List sx={{display: 'flex', flexDirection: 'column'}}>
                    {DrawerItems
                         .map(i =>
                             <DrawerItem
                                 key={i.path}
                                 text={i.title}
                                 path={i.path}
                                 icon={i.icon}
                                 onClick={() => setOpenDrawer(false)}
                                 openDrawer={openDrawer}
                              />)}
                </List>
                <Box flexGrow='1' />
                <ListItem onClick={handleLogout} className={styles.logoutButton}>
                    <Tooltip title={t('LOGOUT')} placement='right'>
                        <LogoutIcon />
                    </Tooltip>
                    {openDrawer && <Typography ml='10px'>{t('LOGOUT')}</Typography>}
                </ListItem>
            </div>
        </Drawer>
    );
};

function DrawerItem({text, icon, path, onClick, openDrawer}) {
    const navigate = useNavigate();
    const selected = !!useMatch(path);

    const handleClick = () => {
        navigate(path);
        onClick();
    };

    return (
        <ListItemButton
            key={text}
            onClick={handleClick}
            selected={selected}
            title={text}
            className={styles.customListItemButton}
        >
            <ListItemIcon className={styles.customListItemIcon}>
                {icon}
            </ListItemIcon>
            {openDrawer && <ListItemText primary={text} className={styles.customListItemText} />}
        </ListItemButton>
    );
}

const AppBarRight = ({isMobile, onClick}) => {
    if (isMobile) {
        return (
            <IconButton onClick={onClick}>
                <MenuIcon />
            </IconButton>
        );
    }

    return <UserInfo />;
};
