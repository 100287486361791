import * as yup from 'yup';

import {validateCNPJ} from './utils';

export const createAccountSchema = (t) =>
    yup.object().shape({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        mu: yup.number().nullable(true).required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        isDemo: yup.boolean().optional().default(false),
        cnpj: yup.string()
            .when('type', {
                is: 'Paid',
                then: yup.string().trim().test('invalid-cnpj', t('ACCOUNTS_PAGE.INVALID_CNPJ'), (value) => value ? validateCNPJ(value) : true).required(t('REQUIRED_FIELD')),
                otherwise: yup.string().trim().test('invalid-cnpj', t('ACCOUNTS_PAGE.INVALID_CNPJ'), (value) => value ? validateCNPJ(value) : true),
            }),
        company_name: yup.string()
            .when('type', {
                is: 'Paid',
                then: yup.string().required(t('REQUIRED_FIELD')).matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'))
                    .test('trim', t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'), value => value === value?.trim()),
                otherwise: yup.string().matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'))
                    .test('trim', t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'), value => value === value?.trim()),
            }),
        address: yup.object().shape({
            postal_code: yup.string().trim().matches(/^\d{5}-?\d{3}$/, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS')),
            street: yup.string()
                .optional()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'), value => value === value?.trim()),
            number: yup.string().trim(),
            neighborhood: yup.string()
                .optional()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'), value => value === value?.trim()),
            city: yup.string()
                .optional()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'), value => value === value?.trim()),
            state: yup.string()
                .optional()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'), value => value === value?.trim()),
            additional_info: yup.string().optional(),
        })
            .when('type', {
                is: 'Paid',
                then: yup.object().shape({
                    postal_code: yup.string()
                        .trim()
                        .matches(/^\d{5}-?\d{3}$/, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS'))
                        .required(t('REQUIRED_FIELD')),
                    street: yup.string().required(t('REQUIRED_FIELD'))
                        .optional()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'), value => value === value?.trim()),
                    number: yup.string().trim().required(t('REQUIRED_FIELD')),
                    neighborhood: yup.string().required(t('REQUIRED_FIELD'))
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'), value => value === value?.trim()),
                    city: yup.string().required(t('REQUIRED_FIELD'))
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'), value => value === value?.trim()),
                    state: yup.string().required(t('REQUIRED_FIELD'))
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'), value => value === value?.trim()),
                }),
                otherwise: yup.object().shape({
                    postal_code: yup.string().trim().min(8, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS')),
                    street: yup.string()
                        .optional()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'), value => value === value?.trim()),
                    number: yup.string().trim(),
                    neighborhood: yup.string()
                        .optional()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'), value => value === value?.trim()),
                    city: yup.string()
                        .optional()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'), value => value === value?.trim()),
                    state: yup.string()
                        .optional()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'), value => value === value?.trim()),
                }),
            }),
        financial_responsible: yup.object({
            name: yup.string(),
            email: yup.string().trim().email(t('INVALID_EMAIL')),
            phone: yup.string(),
        }),
        value_per_user: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')),
                otherwise: yup.number(),
            }),
        min_users_number: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')),
                otherwise: yup.number(),
            }),
        close_invoice: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')),
                otherwise: yup.number(),
            }),
        payment_day: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')).nullable(),
                otherwise: yup.number().nullable(),
            }),
        contract_initial_day: yup.date().nullable(),
        contract_final_day: yup.date().nullable(),
        responsible: yup.object({
            name: yup.string().trim().required(t('REQUIRED_FIELD')),
        }),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
        rp: yup.string().trim().min(8, t('ACCOUNTS_PAGE.VALIDATION.PASSWORD_MIN_LENGTH')).required(t('REQUIRED_FIELD')),
        modules: yup.object({
            climateCheck: yup.boolean(),
        }),
        timezone: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).nullable().required(t('REQUIRED_FIELD')),
        type: yup.string().nullable().required(t('REQUIRED_FIELD')),
    });

export const createDemoAccountSchema = (t) =>
    yup.object({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('DEMO_ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        responsibleName: yup.string().trim().required(t('REQUIRED_FIELD')),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
        expiryDate: yup.date(),
    });

export const editTrainingAccountSchema = (t) =>
    yup.object({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        mu: yup.number().required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('DEMO_ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        responsible: yup.object({
            name: yup.string().trim().required(t('REQUIRED_FIELD')),
        }),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
        expiryDate: yup.date(),
        modules: yup.object({
            climateCheck: yup.boolean(),
        }),
        disabled: yup.boolean(),
    });

export const editAccountSchema = (t) =>
    yup.object().shape({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        mu: yup.number().nullable(true).required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        isDemo: yup.boolean().optional().default(false),
        cnpj: yup.string()
            .when('type', {
                is: 'Paid',
                then: yup.string().trim().test('invalid-cnpj', t('ACCOUNTS_PAGE.INVALID_CNPJ'), (value) => value ? validateCNPJ(value) : true).required(t('REQUIRED_FIELD')).nullable(),
                otherwise: yup.string().trim().test('invalid-cnpj', t('ACCOUNTS_PAGE.INVALID_CNPJ'), (value) => value ? validateCNPJ(value) : true),
            }),
        company_name: yup.string()
            .when('type', {
                is: 'Paid',
                then: yup.string().required(t('REQUIRED_FIELD')).nullable().matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'))
                    .test('trim', t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'), value => value === value?.trim()),
                otherwise: yup.string().nullable().optional().matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'))
                    .test('trim', t('ACCOUNTS_PAGE.VALIDATION.COMPANY_NAME_PATTERN'), value => value === value?.trim()),
            }),
        address: yup.object().shape({
            postal_code: yup.string().trim().matches(/^\d{5}-?\d{3}$/, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS')),
            street: yup.string().matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'), value => value === value?.trim()),
            number: yup.string().trim(),
            neighborhood: yup.string()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'), value => value === value?.trim()),
            city: yup.string()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'), value => value === value?.trim()),
            state: yup.string()
                .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'))
                .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'), value => value === value?.trim()),
            additional_info: yup.string().optional().nullable(),
        })
            .when('type', {
                is: 'Paid',
                then: yup.object().shape({
                    postal_code: yup.string()
                        .trim()
                        .matches(/^\d{5}-?\d{3}$/, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS'))
                        .required(t('REQUIRED_FIELD'))
                        .nullable(),
                    street: yup.string().required(t('REQUIRED_FIELD')).nullable().matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'), value => value === value?.trim()),
                    number: yup.string().required(t('REQUIRED_FIELD')).nullable(),
                    neighborhood: yup.string().required(t('REQUIRED_FIELD')).nullable()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'), value => value === value?.trim()),
                    city: yup.string().required(t('REQUIRED_FIELD')).nullable()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'), value => value === value?.trim()),
                    state: yup.string().required(t('REQUIRED_FIELD')).nullable()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'), value => value === value?.trim()),
                    additional_info: yup.string().optional().nullable(),
                }),
                otherwise: yup.object().shape({
                    postal_code: yup.string().trim().min(8, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS')),
                    street: yup.string().matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STREET_PATTERN'), value => value === value?.trim()),
                    number: yup.string().trim(),
                    neighborhood: yup.string()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.NEIGHBORHOOD_PATTERN'), value => value === value?.trim()),
                    city: yup.string()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.CITY_PATTERN'), value => value === value?.trim()),
                    state: yup.string()
                        .matches(/^(?!.*\s{2,})[A-Za-zÀ-ÿ0-9 '-]+(?:\s[A-Za-zÀ-ÿ0-9 '-]+)*$/, t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'))
                        .test('trim', t('ACCOUNTS_PAGE.VALIDATION.STATE_PATTERN'), value => value === value?.trim()),
                    additional_info: yup.string().optional().nullable(),
                }),
            }),
        financial_responsible: yup.object({
            name: yup.string().nullable(),
            email: yup.string().trim().email(t('INVALID_EMAIL')).nullable(),
            phone: yup.string().nullable(),
        }),
        value_per_user: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')).nullable(),
                otherwise: yup.number().nullable(),
            }),
        min_users_number: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')),
                otherwise: yup.number(),
            }),
        close_invoice: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')),
                otherwise: yup.number(),
            }),
        payment_day: yup.number()
            .when('type', {
                is: 'Paid',
                then: yup.number().required(t('REQUIRED_FIELD')).nullable(),
                otherwise: yup.number().nullable(),
            }),
        contract_initial_day: yup.date().nullable(),
        contract_final_day: yup.date().nullable(),
        responsible: yup.object({
            name: yup.string().trim().required(t('REQUIRED_FIELD')).nullable(),
        }),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD'))
            .nullable(),
        modules: yup.object({
            climateCheck: yup.boolean(),
        }),
        timezone: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).nullable().required(t('REQUIRED_FIELD')),
        type: yup.string().nullable().required(t('REQUIRED_FIELD')),
    });

export const changePasswordAccountSchema = (t) =>
    yup.object({
        password: yup.string().trim().required(t('REQUIRED_FIELD')),
    });

export const accountStatus = {
    READY: 'ready',
    PREPARING: 'preparing',
};

export const accountPayingType = {
    FREE: 'Free',
    PAID: 'Paid',
    EXPERIMENTAL: 'Experimental',
};

export const boletosStatus = {
    PAID: 'Paid',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
};

export const translateAccountStatus = {
    [accountStatus.READY]: 'ACCOUNTS_PAGE.READY',
    [accountStatus.PREPARING]: 'ACCOUNTS_PAGE.PREPARING',
};

export const translateAccountPayingType = {
    [accountPayingType.FREE]: 'ACCOUNTS_PAGE.FREE',
    [accountPayingType.PAID]: 'ACCOUNTS_PAGE.PAY',
    [accountPayingType.EXPERIMENTAL]: 'ACCOUNTS_PAGE.EXPERIMENTAL',
};

export const translateBoletosStatus = {
    [boletosStatus.PAID]: 'ACCOUNTS_PAGE.BOLETOS.PAID',
    [boletosStatus.EXPIRED]: 'ACCOUNTS_PAGE.BOLETOS.EXPIRED',
    [boletosStatus.PENDING]: 'ACCOUNTS_PAGE.BOLETOS.PENDING',
};

export const accountTypes = {
    commercials: 'commercials',
    trainings: 'trainings',
};
export const searchTypes = {
    ALL: 'All',
    ...accountPayingType,
};

export const translateSearchType = {
    ...translateAccountPayingType,
    [searchTypes.ALL]: 'ACCOUNTS_PAGE.ALL',
};
