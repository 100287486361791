
const days = [
    'ACCOUNT_DASHBOARD_PAGE.SUNDAY',
    'ACCOUNT_DASHBOARD_PAGE.MONDAY',
    'ACCOUNT_DASHBOARD_PAGE.TUESDAY',
    'ACCOUNT_DASHBOARD_PAGE.WEDNESDAY',
    'ACCOUNT_DASHBOARD_PAGE.THURSDAY',
    'ACCOUNT_DASHBOARD_PAGE.FRIDAY',
    'ACCOUNT_DASHBOARD_PAGE.SATURDAY',
];
const months = [
    'ACCOUNT_DASHBOARD_PAGE.JANUARY',
    'ACCOUNT_DASHBOARD_PAGE.FEBRUARY',
    'ACCOUNT_DASHBOARD_PAGE.MARCH',
    'ACCOUNT_DASHBOARD_PAGE.APRIL',
    'ACCOUNT_DASHBOARD_PAGE.MAY',
    'ACCOUNT_DASHBOARD_PAGE.JUNE',
    'ACCOUNT_DASHBOARD_PAGE.JULY',
    'ACCOUNT_DASHBOARD_PAGE.AUGUST',
    'ACCOUNT_DASHBOARD_PAGE.SEPTEMBER',
    'ACCOUNT_DASHBOARD_PAGE.OCTOBER',
    'ACCOUNT_DASHBOARD_PAGE.NOVEMBER',
    'ACCOUNT_DASHBOARD_PAGE.DECEMBER',
];

export function calendarLocale(t) {
    return {
        localize: {
            day: n => t(days[n]),
            month: n => t(months[n]),
        },
        formatLong: {
            date: () => 'mm/dd/yyyy',
            dateTime: () => 'dd/MM/yyyy HH:mm',
            time: () => 'HH:mm',
        },
    };
}
