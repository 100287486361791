import {TextField} from '@mui/material';
import {calendarLocale} from '@src/modules/accountDashboard/utils';
import * as moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './date-timer-picker.module.scss';
export function DateTimePicker({value, onChange, pickerProps, inputProps, className, showTimeSelect, label, minDate, popperClassname}) {
    const {t} = useTranslation();
    const {localize, formatLong} = calendarLocale(t);

    function filterPassedTime(time) {
        const currentDate = moment().startOf('day');
        const selectedDate = moment(time).startOf('day');

        if (!value) {
            return false;
        }

        if (moment(currentDate).add(1, 'day').isSame(selectedDate)) {
            const currentHour = moment().hours();
            const selectedHour = moment(time).hours();
            return selectedHour > currentHour;
        }

        return true;
    }

    function handleChange(value) {
        const isMinDate =
            moment(moment().startOf('day'))
                .add(1, 'day')
                .isSame(moment(value).startOf('day'));

        const shortestPossibleHour = moment().hours();

        if (isMinDate && moment(value).hours() < shortestPossibleHour) {
            const newValue = new Date(moment(value).add(shortestPossibleHour + 1, 'hour'));

            onChange(newValue);
        } else {
            onChange(value);
        }
    }

    return (
        <ReactDatePicker
            selected={value}
            onChange={(value) => handleChange(value)}
            timeCaption={t('DEMO_ACCOUNTS_PAGE.FORM.HOUR')}
            minDate={minDate ? minDate : false}
            filterTime={filterPassedTime}
            dateFormat={formatLong.dateTime()}
            showTimeSelect={showTimeSelect ? showTimeSelect : false}
            timeFormat={formatLong.time()}
            timeIntervals={60}
            className={cx(className ? className : '')}
            locale={{
                localize,
                formatLong,
            }}
            customInput={
                <TextField
                    size='medium'
                    label={label || t('DEMO_ACCOUNTS_PAGE.FORM.EXPIRATION_DATE')}
                    InputProps={{
                        readOnly: true,
                    }}
                    {...inputProps}
                />
            }
            popperClassName={popperClassname || styles.popper}
            {...pickerProps}
        />
    );
}
