import * as moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import Chart from 'react-apexcharts';
import ptBR from 'apexcharts/dist/locales/pt-br';
import {useGetUsersAccessCountQuery} from '@src/modules/accountDashboard/accounts.api';
import {DatePicker} from '@src/components/DatePicker';
import {theme} from '@src/utils/theme';

import styles from './users-access-chart.module.scss';

const requestDateFormat = 'YYYY-MM-DDTHH:mm:ss';

export function UsersAccessChart({accountId, accountType}) {
    const [groupBy, setGroupBy] = useState('day');
    const [dateFrom, setDateFrom] = useState(moment().subtract(7, 'days').startOf('day'));
    const [rangeDays, setRangeDays] = useState(7);
    const [dateTo, setDateTo] = useState(moment().endOf('day'));
    const [data, setData] = useState([]);
    const {t} = useTranslation();

    const {data: usersAccessCount} = useGetUsersAccessCountQuery({
        id: `${accountType}/${accountId}`,
        from: moment(dateFrom).format(requestDateFormat),
        to: moment(dateTo).format(requestDateFormat),
        groupBy,
    },
    {skip: !accountId},
    );
    const getDate = () => {
        const currentDate = new Date(dateFrom);
        if (usersAccessCount){
            let tempData = {...usersAccessCount?.dates};
            tempData;
            while (currentDate <= dateTo) {
                const dateString = currentDate.toISOString();
                if (!tempData[dateString]) {
                    tempData[dateString] = 0;
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }

            const sorted = Object.keys(tempData)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = tempData[key];

                    return accumulator;
                }, {});

            setData(sorted);
        }
    };

    const getRange = (range) => {
        return  setDateFrom(moment().subtract(range, 'days').startOf('day'));
    };
    useEffect(() => {
        if (usersAccessCount){
            getDate();
        }
    }, [usersAccessCount]);

    useEffect(() => {
        if (rangeDays !== 0){
            getRange(rangeDays);
            getDate();
        }
    }, [rangeDays]);

    const apexProps = {
        options: {
            chart: {
                locales: [ptBR],
                defaultLocale: 'pt-br',
                toolbar: {
                    tools: {
                        download: false,
                        pan: false,
                        zoom: false,
                    },
                },
            },
            grid: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yyyy',
                    color: 'red',
                },
                marker: {
                    show: true,
                },
                theme: 'dark',
                y: {
                    formatter: (number) => `${number.toFixed()}`,
                },
            },
            xaxis: {
                categories: Object.keys(data || []),
                type: 'datetime',
                labels: {
                    format: groupBy === 'day' ?  'dd/MM/yy ddd' : 'MM/yyyy',
                    style: {
                        colors: 'white',
                        fontWeight: 500,
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: 'white',
                        fontWeight: 500,
                    },
                },
            },
            fill: {
                opacity: 0.3,
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    opacityFrom: 0.7,
                    opacityTo: 0.3,
                },
            },
            colors: [theme.palette.primary.main],

        },
        series: [
            {
                name: t('ACCOUNT_DASHBOARD_PAGE.ACCESS'),
                data: Object.values(data || []),
            },
        ],

    };
    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <FormControl>
                    <DatePicker
                        selected={new Date(dateFrom)}
                        onChange={(date) => setDateFrom(date)}
                        inputId='from'
                        inputLabel={t('ACCOUNT_DASHBOARD_PAGE.FROM')}
                        maxDate={new Date(moment(dateTo).startOf('day'))}
                        sx={{
                            width: 150,
                        }}
                    />
                </FormControl>

                <FormControl>
                    <DatePicker
                        selected={new Date(dateTo)}
                        onChange={(date) => setDateTo(date)}
                        inputId='to'
                        inputLabel={t('ACCOUNT_DASHBOARD_PAGE.TO')}
                        minDate={new Date(moment(dateFrom).endOf('day'))}
                        maxDate={new Date(moment().endOf('day'))}
                        sx={{
                            width: 150,
                        }}
                    />
                </FormControl>

                <FormControl>
                    <InputLabel id='rangeOfDays'>{t('ACCOUNT_DASHBOARD_PAGE.RANGE_OF_DAYS')}</InputLabel>
                    <Select
                        id='rangeOfDays'
                        label={t('ACCOUNT_DASHBOARD_PAGE.RANGE_OF_DAYS')}
                        value={rangeDays}
                        onChange={(e) => setRangeDays(e.target.value)}
                        size='small'
                        className={styles.selectRangeOfDays}
                    >
                        <MenuItem value={7}>{t('ACCOUNT_DASHBOARD_PAGE.NONE')}</MenuItem>
                        <MenuItem value={15}>{t('ACCOUNT_DASHBOARD_PAGE.LAST_DAYS', {days: 15})}</MenuItem>
                        <MenuItem value={30}>{t('ACCOUNT_DASHBOARD_PAGE.LAST_DAYS', {days: 30})}</MenuItem>
                        <MenuItem value={60}>{t('ACCOUNT_DASHBOARD_PAGE.LAST_MONTH', {month: 2})}</MenuItem>
                        <MenuItem value={90}>{t('ACCOUNT_DASHBOARD_PAGE.LAST_MONTH', {month: 3})}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl>
                    <InputLabel id='groupBy'>{t('ACCOUNT_DASHBOARD_PAGE.GROUP_BY')}</InputLabel>
                    <Select
                        id='groupBy'
                        label={t('ACCOUNT_DASHBOARD_PAGE.GROUP_BY')}
                        value={groupBy}
                        onChange={(e) => setGroupBy(e.target.value)}
                        size='small'
                        className={styles.selectGroupBy}
                    >
                        <MenuItem value='day'>{t('ACCOUNT_DASHBOARD_PAGE.DAY')}</MenuItem>
                        <MenuItem value='month'>{t('ACCOUNT_DASHBOARD_PAGE.MONTH')}</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div className={styles.chartContainer}>
                <Chart
                    type='area'
                    options={apexProps.options}
                    series={apexProps.series}
                    height={350}
                />
            </div>
        </div>
    );
}
