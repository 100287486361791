import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import axios from 'axios';

export const allTimezones = [
    {value: 'Pacific/Midway', altName: 'Midway Island, Samoa'},
    {value: 'Pacific/Honolulu', altName: 'Hawaii'},
    {value: 'America/Juneau', altName: 'Alaska'},
    {value: 'America/Boise', altName: 'Mountain Time'},
    {value: 'America/Dawson', altName: 'Dawson, Yukon'},
    {value: 'America/Chihuahua', altName: 'Chihuahua, La Paz, Mazatlan'},
    {value: 'America/Phoenix', altName: 'Arizona'},
    {value: 'America/Chicago', altName: 'Central Time'},
    {value: 'America/Regina', altName: 'Saskatchewan'},
    {value: 'America/Mexico_City', altName: 'Guadalajara, Mexico City, Monterrey'},
    {value: 'America/Belize', altName: 'Central America'},
    {value: 'America/Detroit', altName: 'Eastern Time'},
    {value: 'America/Bogota', altName: 'Bogota, Lima, Quito'},
    {value: 'America/Caracas', altName: 'Caracas, La Paz'},
    {value: 'America/Santiago', altName: 'Santiago'},
    {value: 'America/St_Johns', altName: 'Newfoundland and Labrador'},
    {value: 'America/Sao_Paulo', altName: 'Brasilia'},
    {value: 'America/Tijuana', altName: 'Tijuana'},
    {value: 'America/Montevideo', altName: 'Montevideo'},
    {value: 'America/Argentina/Buenos_Aires', altName: 'Buenos Aires, Georgetown'},
    {value: 'America/Godthab', altName: 'Greenland'},
    {value: 'America/Los_Angeles', altName: 'Pacific Time'},
    {value: 'Atlantic/Azores', altName: 'Azores'},
    {value: 'Atlantic/Cape_Verde', altName: 'Cape Verde Islands'},
    {value: 'GMT', altName: 'UTC'},
    {value: 'Europe/London', altName: 'Edinburgh, London'},
    {value: 'Europe/Dublin', altName: 'Dublin'},
    {value: 'Europe/Lisbon', altName: 'Lisbon'},
    {value: 'Africa/Casablanca', altName: 'Casablanca, Monrovia'},
    {value: 'Atlantic/Canary', altName: 'Canary Islands'},
    {value: 'Europe/Belgrade', altName: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'},
    {value: 'Europe/Sarajevo', altName: 'Sarajevo, Skopje, Warsaw, Zagreb'},
    {value: 'Europe/Brussels', altName: 'Brussels, Copenhagen, Madrid, Paris'},
    {value: 'Europe/Amsterdam', altName: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'},
    {value: 'Africa/Algiers', altName: 'West Central Africa'},
    {value: 'Europe/Bucharest', altName: 'Bucharest'},
    {value: 'Africa/Cairo', altName: 'Cairo'},
    {value: 'Europe/Helsinki', altName: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'},
    {value: 'Europe/Athens', altName: 'Athens'},
    {value: 'Asia/Jerusalem', altName: 'Jerusalem'},
    {value: 'Africa/Harare', altName: 'Harare, Pretoria'},
    {value: 'Europe/Moscow', altName: 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd'},
    {value: 'Asia/Kuwait', altName: 'Kuwait, Riyadh'},
    {value: 'Africa/Nairobi', altName: 'Nairobi'},
    {value: 'Asia/Baghdad', altName: 'Baghdad'},
    {value: 'Asia/Tehran', altName: 'Tehran'},
    {value: 'Asia/Dubai', altName: 'Abu Dhabi, Muscat'},
    {value: 'Asia/Baku', altName: 'Baku, Tbilisi, Yerevan'},
    {value: 'Asia/Kabul', altName: 'Kabul'},
    {value: 'Asia/Yekaterinburg', altName: 'Ekaterinburg'},
    {value: 'Asia/Karachi', altName: 'Islamabad, Karachi, Tashkent'},
    {value: 'Asia/Kolkata', altName: 'Chennai, Kolkata, Mumbai, New Delhi'},
    {value: 'Asia/Kathmandu', altName: 'Kathmandu'},
    {value: 'Asia/Dhaka', altName: 'Astana, Dhaka'},
    {value: 'Asia/Colombo', altName: 'Sri Jayawardenepura'},
    {value: 'Asia/Almaty', altName: 'Almaty, Novosibirsk'},
    {value: 'Asia/Rangoon', altName: 'Yangon Rangoon'},
    {value: 'Asia/Bangkok', altName: 'Bangkok, Hanoi, Jakarta'},
    {value: 'Asia/Krasnoyarsk', altName: 'Krasnoyarsk'},
    {value: 'Asia/Shanghai', altName: 'Beijing, Chongqing, Hong Kong SAR, Urumqi'},
    {value: 'Asia/Kuala_Lumpur', altName: 'Kuala Lumpur, Singapore'},
    {value: 'Asia/Taipei', altName: 'Taipei'},
    {value: 'Australia/Perth', altName: 'Perth'},
    {value: 'Asia/Irkutsk', altName: 'Irkutsk, Ulaanbaatar'},
    {value: 'Asia/Seoul', altName: 'Seoul'},
    {value: 'Asia/Tokyo', altName: 'Osaka, Sapporo, Tokyo'},
    {value: 'Asia/Yakutsk', altName: 'Yakutsk'},
    {value: 'Australia/Darwin', altName: 'Darwin'},
    {value: 'Australia/Adelaide', altName: 'Adelaide'},
    {value: 'Australia/Sydney', altName: 'Canberra, Melbourne, Sydney'},
    {value: 'Australia/Brisbane', altName: 'Brisbane'},
    {value: 'Australia/Hobart', altName: 'Hobart'},
    {value: 'Asia/Vladivostok', altName: 'Vladivostok'},
    {value: 'Pacific/Guam', altName: 'Guam, Port Moresby'},
    {value: 'Asia/Magadan', altName: 'Magadan, Solomon Islands, New Caledonia'},
    {value: 'Asia/Kamchatka', altName: 'Kamchatka, Marshall Islands'},
    {value: 'Pacific/Fiji', altName: 'Fiji Islands'},
    {value: 'Pacific/Auckland', altName: 'Auckland, Wellington'},
    {value: 'Pacific/Tongatapu', altName: 'Nuku\'alofa'},
];

export function getTimezone(lang, timeZone) {
    function format(timeZoneName) {
        return Object.fromEntries(
            new Intl.DateTimeFormat(lang, {timeZone, timeZoneName})
                .formatToParts(new Date())
                .map(({type, value}) => [type, value]),
        ).timeZoneName;
    }

    return `${format('longOffset')} - ${format('long')} (${timeZone})`;
}

export const timezonesAll = allTimezones
    .map(({value}) => {
        return {
            label: getTimezone('pt-BR', value),
            value,
        };
    })
    .sort((a, b) => {
        if (a.label > b.label) {
            return -1;
        }
        if (a.label < b.label) {
            return 1;
        }
        return 0;
    });

export function validateCNPJ(cnpj) {
    const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const c = String(cnpj).replace(/\D/g, '');

    if (c.length !== 14){
        return false;
    }

    if (/0{14}/.test(c)){
        return false;
    }

    let n = 0;
    for (let i = 0; i < 12; i++){
        n += c[i] * b[i + 1];
    }

    if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)){
        return false;
    }

    n = 0;
    for (let i = 0; i <= 12; i++){
        n += c[i] * b[i];
    }

    if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)){
        return false;
    }

    return true;
}

export function formatPhone(value) {
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
        value = value.slice(0, 11);
    }

    if (value.length > 10) {
        return value
            .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (value.length > 6) {
        return value
            .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else if (value.length > 2) {
        return value
            .replace(/(\d{2})(\d{0,5})/, '($1) $2');
    } else if (value.length > 1) {
        return value.replace(/(\d{0,2})/, '($1');
    } else {
        return value;
    }
}

const api = axios.create({
    baseURL: 'https://viacep.com.br/ws/',
});

export const handleSearch = async (event, setDataCep) => {
    if (event.target.value.length === 9) {
        const data = await api.get(`${event.target.value}/json`);
        setDataCep(data.data);
    }
    return;
};

export const formatCEP = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
};

const formatCNPJ = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
};

export const handleCNPJChange = (event, formik) => {
    const cnpjFormated = formatCNPJ(event.target.value);
    formik.setFieldValue('cnpj', cnpjFormated);
};

export const handleCEPChange = (event, formik) => {
    const formattedCEP = formatCEP(event.target.value);
    formik.setFieldValue('address.postal_code', formattedCEP);
};

export const handleSearchData = (e, setDataCep) => {
    handleSearch(e, setDataCep);
};

export const handlePhoneChange = (event, formik) => {
    const formattedPhone = formatPhone(event.target.value);
    formik.setFieldValue('financial_responsible.phone', formattedPhone);
};

export const numberFilter = (e) => {
    let theEvent = e || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    let regex = /^[0-9]+$/;

    if (e.key == 0 && e.target.value.length == 0) {
        e.preventDefault();
    } else if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) {
            theEvent.preventDefault();
        }
    }
};

export const handleOnWheel = (e) => {
    e.target.blur();
};

export function toCurrency(valueInCents = 0) {
    return `R$${formatCurrency(valueInCents / 100)}`;
}

export const accountsType = [{
    label: 'ACCOUNTS_PAGE.EXPERIMENTAL',
    value: 'Experimental',
}, {
    label: 'ACCOUNTS_PAGE.FREE',
    value: 'Free',
}, {
    label: 'ACCOUNTS_PAGE.PAY',
    value: 'Paid',
}];

export const accountsTypesValues = {
    'Experimental': {label: 'ACCOUNTS_PAGE.EXPERIMENTAL', value: 'Experimental'},
    'Free': {label: 'ACCOUNTS_PAGE.FREE', value: 'Free'},
    'Paid': {label: 'ACCOUNTS_PAGE.PAY', value: 'Paid'},
};

export const createAccountInitialValues = {
    name: '',
    subdomain: '',
    mu: '',
    cnpj: '',
    company_name: '',
    address: {
        postal_code: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        additional_info: '',
    },
    financial_responsible: {
        name: '',
        email: '',
        phone: '',
    },
    value_per_user: undefined,
    min_users_number: undefined,
    close_invoice: undefined,
    payment_day: undefined,
    contract_initial_day: undefined,
    contract_final_day: undefined,
    responsible: {
        name: '',
    },
    re: '',
    rp: '',
    active: '',
    created: '',
    modules: {
        climateCheck: false,
    },
    timezone: null,
    expiryDate: null,
    type: null,
};
