import React, {useEffect, useState} from 'react';
import {
    Box,
    CircularProgress,
    Container,
} from '@mui/material';
import {
    useGetByIdQuery,
    useGetAccountUsersQuery,
} from '@src/modules/accounts/accounts.api';
import {accountTypes} from '@src/modules/accounts/schema';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {timezonesAll} from '@src/modules/accounts/utils';

import DemoAccountEdit from '../DemoAccountEdit';

import {EditForm} from './components/EditForm';
import styles from './account-edit.module.scss';

function AccountEdit() {
    const [initialValues, setInitialValues] = useState(null);

    const {t} = useTranslation();
    const {id} = useParams();
    
    const {data} = useGetByIdQuery(
        {id: `${accountTypes.commercials}/${id}`},
        {skip: !id},
    );
    const {data: accountUsers} = useGetAccountUsersQuery(
        {id: `${accountTypes.commercials}/${id}`},
        {skip: !id},
    );

    useEffect(() => {
        if (!!id && data && accountUsers) {
            const {
                name,
                subdomain,
                max_users,
                cnpj,
                company_name,
                address,
                financial_responsible,
                value_per_user,
                min_users_number,
                close_invoice,
                payment_day,
                contract_initial_day,
                contract_final_day,
                contractUrl,
                disabled,
                responsible,
                modules,
                timezone,
                expiry_date,
                type,
            } = data;
            const selectedTimezone = timezonesAll.find((option) => option.value === timezone);

            setInitialValues({
                name,
                subdomain,
                mu: max_users,
                cnpj: cnpj ? cnpj : '',
                company_name: company_name || '',
                address: {
                    postal_code: address?.postal_code || '',
                    street: address?.street || '',
                    number: address?.number || '',
                    neighborhood: address?.neighborhood || '',
                    city: address?.city || '',
                    state: address?.state || '',
                    additional_info: address?.additional_info || '',
                },
                financial_responsible: {
                    name: financial_responsible?.name,
                    email: financial_responsible?.email,
                    phone: financial_responsible?.phone,
                },
                value_per_user,
                min_users_number: min_users_number || '',
                close_invoice: close_invoice || '',
                payment_day,
                contract_initial_day: contract_initial_day ? new Date(contract_initial_day) :  null,
                contract_final_day: contract_final_day ? new Date(contract_final_day) : null,
                contractUrl,
                contract: '',
                responsible: {
                    name: responsible?.name,
                },
                re: responsible ? responsible?.email : '',
                active: accountUsers?.active ? accountUsers?.active : '',
                created: accountUsers?.created ? accountUsers?.created : '',
                modules: {
                    climateCheck: modules?.climateCheck,
                },
                disabled,
                timezone: selectedTimezone,
                expiryDate: expiry_date ? new Date(expiry_date) : undefined,
                type,
            });
        }
    }, [data, id, accountUsers]);

    return (
        <Container maxWidth='xl'>
            <Box className={styles.header}>
                <h1>{t('ACCOUNTS_PAGE.EDIT_TITLE')}</h1>
            </Box>

            {initialValues ? (
                <EditForm
                    initialValues={initialValues}
                    logoUrl={data?.logoUrl}
            />
        ) : (
            <Box
                sx={{
                    height: 120,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress color='inherit' />
            </Box>
        )}
        </Container>
    );
}

export default ({...props}) => {
    const {accountType} = useParams();
    return accountType === accountTypes.commercials ? <AccountEdit {...props} /> : <DemoAccountEdit {...props} />;
};
