import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormikTextField} from '@src/components/FormikFields';
import {Tooltip} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {handleCEPChange, handleCNPJChange, handleSearchData} from '../../utils';

import styles from './accountData.module.scss';
export default function AccountData({formik, setDataCep, readOnly, disabled}){
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.soloItem}>
                    <FormikTextField
                        fullWidth
                        name='company_name'
                        label={t('ACCOUNTS_PAGE.SOCIAL_NAME')}
                        formik={formik}
                        readOnly={readOnly}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: <ValidationTooltip />,
                        }}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='cnpj'
                        label={t('ACCOUNTS_PAGE.CNPJ')}
                        formik={formik}
                        onChange={(e) => handleCNPJChange(e,  formik)}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.postal_code'
                        value={formik.values?.address?.postal_code}
                        label={t('ACCOUNTS_PAGE.CEP')}
                        formik={formik}
                        onChange={(e) => handleCEPChange(e, formik)}
                        onBlur={(e) => handleSearchData(e, setDataCep)}
                        error={Boolean(formik.touched.address?.postal_code && formik.errors.address?.postal_code)}
                        helperText={formik.touched.address?.postal_code && formik.errors.address?.postal_code}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
        
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.street'
                        label={t('ACCOUNTS_PAGE.PUBLIC_PLACE')}
                        formik={formik}
                        value={formik.values?.address?.street}
                        error={Boolean(formik.touched.address?.street && formik.errors.address?.street)}
                        helperText={formik.touched.address?.street && formik.errors.address?.street}
                        readOnly={readOnly}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: <ValidationTooltip />,
                        }}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.neighborhood'
                        label={t('ACCOUNTS_PAGE.NEIGHBORHOOD')}
                        formik={formik}
                        value={formik.values?.address?.neighborhood}
                        error={Boolean(formik.touched.address?.neighborhood && formik.errors.address?.neighborhood)}
                        helperText={formik.touched.address?.neighborhood && formik.errors.address?.neighborhood}
                        readOnly={readOnly}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: <ValidationTooltip />,
                        }}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.city'
                        label={t('ACCOUNTS_PAGE.CITY')}
                        formik={formik}
                        value={formik.values?.address?.city}
                        error={Boolean(formik.touched.address?.city && formik.errors.address?.city)}
                        helperText={formik.touched.address?.city && formik.errors.address?.city}
                        readOnly={readOnly}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: <ValidationTooltip />,
                        }}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.state'
                        label={t('ACCOUNTS_PAGE.STATE')}
                        formik={formik}
                        value={formik.values?.address?.state}
                        error={Boolean(formik.touched?.address?.state && formik.errors?.address?.state)}
                        helperText={formik.touched?.address?.state && formik.errors?.address?.state}
                        readOnly={readOnly}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: <ValidationTooltip />,
                        }}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.additional_info'
                        label={t('ACCOUNTS_PAGE.COMPLEMENT')}
                        formik={formik}
                        value={formik.values?.address?.additional_info}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='address.number'
                        label={t('ACCOUNTS_PAGE.NUMBER')}
                        formik={formik}
                        value={formik.values?.address?.number}
                        error={Boolean(formik.touched.address?.number && formik.errors.address?.number)}
                        helperText={formik.touched.address?.number && formik.errors.address?.number}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
}

function ValidationTooltip() {
    const {t} = useTranslation();
    return (
        <Tooltip
            className={styles.tooltip}
            title={
                <ul>
                    <li>{t('ACCOUNTS_PAGE.VALIDATION.SPECIAL_CHARACTER')}</li>
                    <li>{t('ACCOUNTS_PAGE.VALIDATION.TRIM_SPACES')}</li>
                    <li>{t('ACCOUNTS_PAGE.VALIDATION.CONSECUTIVE_SPACES')}</li>
                    <li>{t('ACCOUNTS_PAGE.VALIDATION.ALLOWED_CHARACTERS')}</li>
                    <li>{t('ACCOUNTS_PAGE.VALIDATION.REQUIRED')}</li>
                </ul>
                }
              >
            <InfoOutlinedIcon />
        </Tooltip>    
    );
}
