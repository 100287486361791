import {apiSlice} from '@src/store/apiSlice';
import {providesList} from '@src/utils/modules';

import {accountStatus, accountTypes} from './schema';

const boletosTags = {
    boletosTags: 'BOLETOS',
};
export const accountsApi = apiSlice.injectEndpoints({
    tagTypes: ['Accounts'],
    endpoints: (builder) => ({
        getById: builder.query({
            query: ({id}) => ({url: `/accounts/${id}`}),
            providesTags: (_result, _error, {id}) => [{type: 'Accounts', id}],
        }),
        listAll: builder.query({
            query: ({type = accountTypes.commercials, params}) => ({
                url: `/accounts/${accountTypes[type]}`,
                params,
            }),
            providesTags: (result) => providesList(result?.data, 'Accounts'),
        }),
        listAllTrainingAccounts: builder.query({
            query: ({type = accountTypes.commercials, params}) => ({
                url: `/accounts/${accountTypes[type]}`,
                params,
            }),
            providesTags: (result) => providesList(result?.data, 'Accounts'),
            transformResponse: (response) => {
                const isPreparing = response?.some(item => item.status === accountStatus.PREPARING);
                return {data: response, isPreparing};
            },
        }),
        getAccounts: builder.query({
            query: () => ({
                url: '/accounts',
            }),
            providesTags: (result) => providesList(result, 'Accounts'),

        }),
        create: builder.mutation({
            query: ({type, data}) => {
                return {
                    url: `/accounts/${accountTypes[type]}`,
                    method: 'POST',
                    body: type === accountTypes.trainings
                        ? {
                            name: data.name,
                            subdomain: data.subdomain,
                            responsible: {
                                name: data.responsibleName,
                                email: data.re,
                                password: data.rp,
                            },
                        }
                        : {
                            name: data.name,
                            subdomain: data.subdomain,
                            max_users: data.mu === '' ? null : data.mu,
                            cnpj: data.cnpj,
                            company_name: data.company_name,
                            responsible: {
                                name: data.responsible.name,
                                email: data.re,
                                password: data.rp,
                            },
                            address: {
                                postal_code: data.address.postal_code,
                                street: data.address.street,
                                number: data.address.number,
                                neighborhood: data.address.neighborhood,
                                city: data.address.city,
                                state: data.address.state,
                                additional_info: data.address.additional_info === '' ? null : data.address.additional_info,
                            },
                            financial_responsible: {
                                name: data.financial_responsible?.name,
                                email: data.financial_responsible.email,
                                phone: data.financial_responsible.phone,
                            },
                            value_per_user: data.value_per_user,
                            min_users_number: data.min_users_number,
                            close_invoice: data.close_invoice,
                            payment_day: data.payment_day,
                            contract_initial_day: data.contract_initial_day,
                            contract_final_day: data.contract_final_day,
                            modules: {
                                climateCheck: data.modules.climateCheck,
                            },
                            timezone: data.timezone.value,
                            type: data.type,
                        },
                };
            },
            invalidatesTags: [{type: 'Accounts', id: 'LIST'}],
        }),
        edit: builder.mutation({
            query: ({id, body}) => ({
                url: `/accounts/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'Accounts', id: args.id}, {type: 'Accounts'}],
        }),
        updateResponsiblePassword: builder.mutation({
            query: ({id, body}) => ({
                url: `/accounts/${id}/password`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'Accounts', id: args.id}],
        }),
        toggle: builder.mutation({
            query: ({id, body}) => ({
                url: `/accounts/${id}/disabled`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'Accounts', id: args.id}],
        }),
        getAccountLogoUploadUrl: builder.query({
            query: ({id, params}) => ({
                url: `/accounts/${id}/logoUrl`,
                params,
            }),
        }),
        getAccountUsers: builder.query({
            query: ({id}) => ({url: `/accounts/${id}/users/count`}),
            providesTags: (_result, _error, {id}) => [{type: 'accountUsers', id}],
        }),
        updateExpiryDate: builder.mutation({
            query: ({id, body}) => ({
                url: `/accounts/trainings/${id}/expiry-date`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_result, _error, args) => [{type: 'Accounts', id: args.id}],
        }),
        getContractUrl: builder.mutation({
            query: ({accountId, params}) => ({
                url: `/accounts/commercials/${accountId}/contractUrl`,
                params,
            }),
        }),
        listAllBoletos: builder.query({
            query: ({type = accountTypes.commercials, id}) => ({
                url: `/accounts/${accountTypes[type]}/${id}/boletos`,
            }),
            providesTags: (_result, _error, args) =>  [{type: boletosTags.boletosTags, id: args.accountId}],
        }),
        duplicateBoleto: builder.mutation({
            query: ({type = accountTypes.commercials, accountId, id}) => ({
                url: `/accounts/${accountTypes[type]}/${accountId}/boletos/duplicate/${id}`,
                method: 'POST',
            }),
            invalidatesTags: (_result, _error, args) => [{type: boletosTags.boletosTags, id: args.accountId}],
        }),
    }),
});

export const invalidateAccount = (id) => {
    return accountsApi.util.invalidateTags([{type: 'Accounts', id}]);
};

export const invalidateUsersAmount = (id) => {
    return accountsApi.util.invalidateTags([{type: 'accountUsers', id}]);
};

export const {
    useGetByIdQuery,
    useListAllQuery,
    useListAllTrainingAccountsQuery,
    useEditMutation,
    useToggleMutation,
    useCreateMutation,
    useGetAccountsQuery,
    useGetAccountUsersQuery,
    useLazyGetAccountLogoUploadUrlQuery,
    useUpdateExpiryDateMutation,
    useUpdateResponsiblePasswordMutation,
    useGetContractUrlMutation,
    useListAllBoletosQuery,
    useDuplicateBoletoMutation,
} = accountsApi;
