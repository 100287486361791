import React from 'react';
import {Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip} from '@mui/material';
import LogoUpload from '@src/components/LogoUpload';
import AccountCopyLink from '@src/modules/accounts/components/AccountCopyLink';
import AccountDisabledSwitch from '@src/modules/accounts/components/AccountDisabledSwitch';
import config from '@src/utils/config';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {FormikTextField} from '@src/components/FormikFields';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {accountsType, handleOnWheel, numberFilter, timezonesAll} from '@src/modules/accounts/utils';
import ResponsibleDetails from '@src/modules/accounts/components/ResponsibleDetails';
import {accountTypes} from '@src/modules/accounts/schema';
import {routes} from '@src/utils/routes';
import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import {LoadingButton} from '@mui/lab';

import styles from './accountEditTab.module.scss';

export function AccountEditTab({logoUrl, formik, isLoading}) {
    const [avatarUrl, setAvatarUrl] = useState(logoUrl);
    const {t} = useTranslation();

    const navigate = useNavigate();
    const can = useCan();
    const {accountType, id} = useParams();
    const permissionResource = permissionResources.commercialAccounts;

    const link = {
        url: 'https://' + config.appDomainMask.replace('*', formik.values.subdomain),
    };

    return (
        <form
            className={styles.form}
            onSubmit={formik.handleSubmit}
            autoComplete='off'>
            <LogoUpload
                accountId={`commercials/${id}`}
                defaultAvatar={avatarUrl}
                onReceiveNewImage={setAvatarUrl}
                    />
            <div className={styles.container}>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <FormikTextField
                            fullWidth
                            name='name'
                            label={t('ACCOUNTS_PAGE.FORM.NAME')}
                            formik={formik}
                            onChange={(e) => formik.setFieldValue('name',  e.target.value)}
                            readOnly={isLoading}
                            disabled={isLoading}
                            />
                    </div>
                    <div className={styles.item}>
                        <Box sx={{display: 'flex', gap: 2}}>
                            <FormikTextField
                                fullWidth
                                name='subdomain'
                                label={t('ACCOUNTS_PAGE.FORM.SUBDOMAIN')}
                                formik={formik}
                                readOnly={isLoading}
                                InputProps={{
                                          endAdornment: (
                                              <Tooltip
                                                  className={styles.tooltip}
                                                  title={
                                                      <ul>
                                                          <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.LOWER_CASE')}</li>
                                                          <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPACES')}</li>
                                                          <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.HYPHENS')}</li>
                                                          <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPECIAL_CHARACTER')}</li>
                                                          <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.MARKERS')}</li>
                                                      </ul>
                                                    }
                                                >
                                                  <InfoOutlinedIcon />
                                              </Tooltip> 
                                            ),
                                }}
                                disabled={isLoading}
                                onKeyUp={() => formik.setFieldValue('subdomain', formik.values.subdomain.toLowerCase())}
                                />
                        </Box>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.item}>
                        <FormikTextField
                            name='mu'
                            label={t('ACCOUNTS_PAGE.MAX_NUMBER_USERS')}
                            formik={formik}
                            readOnly={isLoading}
                            disabled={isLoading}
                            type='number'
                            InputProps={{inputProps: {min: 1}}}
                            onKeyPress={(e) => numberFilter(e)}
                            onWheel={handleOnWheel}
                            fullWidth
                            />
                    </div>
                    <div className={styles.item}>
                        <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>{t('ACCOUNTS_PAGE.TYPE')}</InputLabel>
                            <Select
                                name='type'
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                label={t('ACCOUNTS_PAGE.TYPE')}
                                value={formik?.values.type}
                                onChange={(_, value) => {
                                                    formik.setFieldValue('type', value.props.value);
                                }}
                                        >
                                {accountsType.map((i) =>
                                    <MenuItem value={i.value} key={i.value}>{t(i.label)}</MenuItem>,
                                        )}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.soloItem}>
                        <Autocomplete
                            options={timezonesAll}
                            getOptionLabel={(option) => option?.label}
                            value={formik?.values?.timezone}
                            onChange={(_, value) => {
                                        formik.setFieldValue('timezone', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={t('ACCOUNTS_PAGE.FORM.TIMEZONE')}
                                    error={Boolean(formik.touched.timezone && formik.errors.timezone)}
                                    helperText={formik.touched.timezone && formik.errors.timezone}
                                        />
                            )}
                            />
                    </div>
                </div>

                <ResponsibleDetails
                    isEdit
                    formik={formik}
                    showPasswordConfirmation={false}
                    readOnly={isLoading}
                    disabled={isLoading}
                />
                <div className={styles.row}>
                    <div className={styles.item}>
                        <AccountCopyLink link={link} />
                    </div>
                    <div className={styles.item}>
                        {accountType === accountTypes.commercials &&
                            <AccountDisabledSwitch formik={formik} />}  
                    </div>
                </div>
            </div>
            {can(permissionResource, permissionTypes.edit) && (
                <div className={styles.buttonsContainer}>
                    <Button
                        variant='outlined'
                        disabled={isLoading}
                        onClick={() => navigate(routes.ACCOUNTS)}
                         >
                        {t('ACCOUNTS_PAGE.CANCEL')}
                    </Button>

                    <LoadingButton
                        type='submit'
                        loading={isLoading}
                        disabled={isLoading || !formik.dirty}
                        variant='contained'
                         >
                        {t('ACCOUNTS_PAGE.SAVE')}
                    </LoadingButton>
                </div>
                 )}
        </form>
    );
}
