import {FormControl, FormHelperText, InputLabel, Select} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export function FormikSelect({formik, name, maxLength, onChange, children, label, ...props}) {
    const {t} = useTranslation();

    return (
        <FormControl fullWidth>
            <InputLabel 
                error={Boolean(formik.touched[name] && formik.errors[name])} 
                id={name}>
                {t('ACCOUNTS_PAGE.TYPE')}
            </InputLabel>
            <Select
                name={name}
                labelId={name}
                id={name}
                label={label}
                value={formik?.values.type}
                error={Boolean(formik.touched[name] && formik.errors[name])}
                onChange={(_, value) => {
                            formik.setFieldValue(name, value.props.value);
                }}
                {...props}
                >
                {children}
                {props.multiline && maxLength &&
                    <span style={{
                        textAlign: 'right',
                        display: 'block',
                        fontSize: 12,
                        marginTop: formik.touched[name] && formik.errors[name] ? -20 : 0,
                        opacity: props.disabled ? 0.5 : 1,
                    }}>
                        {formik?.values?.[name].length}/{maxLength}
                    </span>
}
            </Select>
            <FormHelperText
                error={Boolean(formik.touched[name] && formik.errors[name])}
             >{formik.touched[name] && formik.errors[name]}</FormHelperText>
        </FormControl>
    );
}
