export const routes = {
    ROOT: '/',
    LOGIN: '/login',
    HOME: '/home',
    ACCOUNTS: '/accounts',
    ACCOUNT_CREATE: '/account-create',
    ACCOUNT_EDIT: (accountType = ':accountType', id = ':id') => `/account-edit/${accountType}/${id}`,
    ACCOUNT_DASHBOARD: (accountType = ':accountType', accountId = ':accountId') => `/account-dashboard/${accountType}/${accountId}`,
    DEMO_ACCOUNTS: '/demo-accounts',
    DEMO_ACCOUNT_CREATE: '/demo-account-create',
    IMPORT_USERS: (accountId = ':accountId') => `/import-users/${accountId}`,
    FORGET_PASSWORD: '/forget-password',
    INSERT_CODE: '/insert-code',
    INSERT_PASSWORD: '/insert-password',
    FAQ: '/faq',
    FAQ_CREATE: '/faq/add',
    FAQ_EDIT: (faqId = ':faqId') => `/faq/${faqId}`,
    SUGGESTIONS: '/notes',
    SUGGESTIONS_DETAILS: (noteId = ':noteId') => `/notes/${noteId}`,
    TUTORIALS: '/tutorials',
    TUTORIALS_CREATE: '/tutorials/add',
    TUTORIALS_EDIT: (tutorialId = ':tutorialId') => `/tutorials/${tutorialId}`,
    TRAININGS: '/trainings',
    TRAININGS_CREATE: '/trainings/add',
    TRAININGS_EDIT: (trainingId = ':trainingId') => `/trainings/${trainingId}`,
    TRAININGS_TOPICS: (trainingId = ':trainingId') => `/trainings/${trainingId}/topics`,
    ADMINS: '/admins',
    ADMINS_CREATE: '/admins/add',
    ADMINS_EDIT: (adminId = ':adminId') => `/admins/${adminId}`,
};
