import React, {createContext, useCallback, useMemo, useState} from 'react';
import {Box, Chip, IconButton, Switch, Tooltip} from '@mui/material';
import Table from '@src/components/Table';
import {useTranslation} from 'react-i18next';
import {Edit, Dashboard, GroupAddOutlined} from '@mui/icons-material';
import {useContext} from 'react';
import AlertDialog from '@src/components/AlertDialog';
import {routes} from '@src/utils/routes';
import {useNavigate} from 'react-router-dom';
import {useCan} from '@src/hooks/useCan';
import {permissionTypes, permissionResources} from '@src/hooks/usePrivateRoutes';
import {useDispatch} from 'react-redux';
import * as moment from 'moment';

import {invalidateAccount, useToggleMutation, useUpdateExpiryDateMutation} from '../../accounts.api';
import AccountPasswordModal from '../AccountPasswordModal';
import {accountTypes, translateAccountPayingType} from '../../schema';
import AccountActions from '../AccountActions';

import styles from './accountTable.module.scss';

const AccountTableContext = createContext({});

function AccountTable({accountType = accountTypes.commercials, data, handleEdit, isLoading}) {
    const [toggleId, setToggleId] = useState(null);
    const [id, setId] = useState(null);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [toggle, {isLoading: isToggleLoading}] = useToggleMutation();
    const [updateExpiryDate] = useUpdateExpiryDateMutation();

    const columns = useMemo(() => {
        const columnsTraining = [
            {accessor: 'name', Header: t('DEMO_ACCOUNTS_PAGE.ACCOUNT'), Cell: ({cell: {row}}) => <Name data={row.original} />},
            {accessor: 'subdomain', Header: t('ACCOUNTS_PAGE.FORM.SUBDOMAIN')},
            {accessor: 'created_at', Header: t('DEMO_ACCOUNTS_PAGE.CREATED_AT'), Cell: ({cell: {value}}) => value ? formatDate(value) : ''},
            {accessor: 'updated_at', Header: t('DEMO_ACCOUNTS_PAGE.UPDATED_AT'), Cell: ({cell: {value}}) => value ? formatDate(value) : ''},
            {accessor: 'disabled', Header: t('DEMO_ACCOUNTS_PAGE.ENABLE'), Cell: ({cell: {value}}) => (
                <Chip label={value} color={value ===  t('ACCOUNTS_PAGE.ACCOUNT_ENABLED') ? 'success' : 'warning'} size='small' variant='outlined'  />
            )},
        ];
        const columnType = {accessor: 'type', Header: t('ACCOUNTS_PAGE.TYPE'), Cell: ({cell: {value}}) =>  t(translateAccountPayingType[value])};

        if (accountType === accountTypes.commercials) {
            columnsTraining.splice(2, 0, columnType);
        }

        return columnsTraining;
    }, [accountType]);

    const rows = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map((row) => ({...row, disabled: row.disabled ? t('ACCOUNTS_PAGE.ACCOUNT_DISABLED') : t('ACCOUNTS_PAGE.ACCOUNT_ENABLED')}));
    }, [data]);

    const currentAccount = useMemo(() => data && toggleId && data.find(item => item.id === toggleId), [data, toggleId]);
    const accountStatusTitle = useMemo(() => t(`ACCOUNTS_PAGE.${currentAccount?.disabled  ? 'ENABLE' : 'DISABLE'}_ALERT.TITLE`), [currentAccount]);
    const accountStatusDescription = useMemo(() => t(`ACCOUNTS_PAGE.${currentAccount?.disabled  ? 'ENABLE' : 'DISABLE'}_ALERT.DESCRIPTION`), [currentAccount]);

    const handleClose = () => {
        setToggleId(null);
    };

    const handleConfirm = useCallback(async () => {
        if (currentAccount?.is_demo && currentAccount?.disabled) {
            const isExpired = moment(currentAccount?.expiry_date).isBefore(moment());

            if (isExpired) {
                await updateExpiryDate({id: currentAccount.id, body: ''});
            }
        }

        await toggle({id: `${accountType}/${toggleId}`, body: !currentAccount?.disabled}).unwrap();

        dispatch(invalidateAccount(toggleId));
        setToggleId(null);
    }, [toggleId, currentAccount]);

    const formatDate = (date) => {
        const momentDateFormatted = moment(date).format('L HH:mm');

        return momentDateFormatted;
    };

    return (
        <AccountTableContext.Provider value={{rows, id, setId, setToggleId, handleEdit, accountType}}>
            <Table
                columns={columns}
                data={rows}
                notFoundText={t('ACCOUNTS_PAGE.NO_DATA_FOUND')}
                paginated={false}
                loading={isLoading}
                Actions={Actions}
                sortBy={'created_at'}
            />
            <AlertDialog
                open={!!toggleId}
                title={accountStatusTitle}
                description={accountStatusDescription}
                isLoading={isToggleLoading}
                onClose={handleClose}
                onConfirm={handleConfirm}
                confirmColor={currentAccount?.disabled ? 'success' : 'error'}
            />

            {!!id && (
                <AccountPasswordModal id={`${accountType}/${id}`} setId={setId} />
            )}
        </AccountTableContext.Provider>
    );
}

const Actions = ({id}) => {
    const {t} = useTranslation();
    const {rows, handleEdit, accountType, setToggleId} = useContext(AccountTableContext);
    const navigate = useNavigate();
    const can = useCan();
    const permissionResource = accountType === accountTypes.commercials ? permissionResources.commercialAccounts : permissionResources.trainingAccounts;
    
    const item = useMemo(() => rows.find(r => r.id === id), [rows]);
    const isChecked = useMemo(() => item.disabled === t('ACCOUNTS_PAGE.ACCOUNT_ENABLED'), [item, t]);
    
    const onChange = () => {
        setToggleId(id);
    };
    
    const onEdit = () => {
        handleEdit(id);
    };

    const navigateToDashboard = () => {
        navigate(routes.ACCOUNT_DASHBOARD(accountTypes.commercials, id));
    };

    const navigateToImportUsers = () => {
        navigate(routes.IMPORT_USERS(id));
    };

    const trainingAccountActions = [
        {
            id: 'edit',
            icon: <Edit />,
            label: t('ACCOUNTS_PAGE.TOOLTIPS.VIEW'),
            onClick: onEdit,
            allowed: can(permissionResource, permissionTypes.edit) || can(permissionResource, permissionTypes.detail),
        }, {
            id: 'importUsers',
            icon: <GroupAddOutlined />,
            label: t('ACCOUNTS_PAGE.TOOLTIPS.IMPORT_USERS'),
            onClick: navigateToImportUsers,
            allowed: can(permissionResource, permissionTypes.edit),

        }].filter(i => i.allowed);

    return (
        <div className={styles.actions}>
            {item?.is_demo && can(permissionResource, permissionTypes.edit) && 
                <Tooltip title={t('ACCOUNTS_PAGE.TOOLTIPS.TOGGLE')}>
                    <Switch color='success' size='small' checked={isChecked} onClick={onChange} />
                </Tooltip>
            }
            {!item?.is_demo && (
                <>
                    {(can(permissionResource, permissionTypes.edit) || can(permissionResource, permissionTypes.detail)) &&
                        <Tooltip title={t('ACCOUNTS_PAGE.TOOLTIPS.VIEW')}>
                            <IconButton 
                                size='small'
                                onClick={onEdit}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    }
                    {can(permissionResource, permissionTypes.detail) &&
                        <Tooltip title={t('ACCOUNTS_PAGE.TOOLTIPS.DASHBOARD')}>
                            <IconButton 
                                size='small'
                                onClick={navigateToDashboard}
                            >
                                <Dashboard />
                            </IconButton>
                        </Tooltip>
                    }
                </>
            )}
            {item?.is_demo &&  
                <AccountActions
                    items={trainingAccountActions}
            />}
        </div>
    );
};

const Name = ({data}) => {
    return (
        <Box className={styles.nameContainer}>
            <span>{data.name}</span>
        </Box>
    );
};

export default AccountTable;
