import {TextField} from '@mui/material';
import * as moment from 'moment';
import React, {forwardRef} from 'react';
import ReactDatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import {calendarLocale} from '@src/modules/accountDashboard/utils';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './date-picker.module.scss';

const CustomPicker = forwardRef(({value, onClick, inputId, inputLabel, ...restCustomInput}, ref) => (
    <TextField 
        size='small'
        ref={ref}
        value={moment(value).format('DD/MM/YYYY')}
        onClick={onClick}
        id={inputId}
        label={inputLabel}
        InputProps={{
            readOnly: true,
        }}
        {...restCustomInput}
    />
));

export function DatePicker({selected, onChange, inputId, inputLabel, maxDate, minDate, ...restCustomInput}) {    
    const {t} = useTranslation();

    const {formatLong, localize} = calendarLocale(t);
    
    return (
        <ReactDatePicker
            selected={selected}
            onChange={(date) => onChange(date)}
            maxDate={maxDate}
            minDate={minDate}
            customInput={<CustomPicker inputId={inputId} inputLabel={inputLabel} {...restCustomInput} />}
            locale={{
                formatLong,
                localize,
            }}
            popperClassName={styles.calendar}
        />
    );
}
