import AccountData from '@src/modules/accounts/components/AccountData';
import {useEffect, useState} from 'react';
import React from 'react';

export function ClientCreateTab({formik, isLoading}) {
    const [dataCep, setDataCep] = useState();
    useEffect(() => {
        if (dataCep) {
            formik.setFieldValue('address.postal_code', dataCep?.erro ? '' : dataCep?.cep);
            formik.setFieldValue('address.street', dataCep?.erro ? '' : dataCep?.logradouro);
            formik.setFieldValue('address.neighborhood', dataCep?.erro ? '' : dataCep?.bairro);
            formik.setFieldValue('address.city', dataCep?.erro ? '' : dataCep?.localidade);
            formik.setFieldValue('address.state', dataCep?.erro ? '' : dataCep?.uf);
        }
    }, [dataCep]);

    return (
        <AccountData
            formik={formik}
            setFieldValue={formik.setFieldValue}
            setDataCep={setDataCep}
            readOnly={isLoading}
            disabled={isLoading}
        />
    );
}
