import React, {useState} from 'react';
import LogoUpload from '@src/components/LogoUpload';
import {FormikTextField} from '@src/components/FormikFields';
import {Autocomplete, Box, Button, FormHelperText, TextField, Tooltip} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {handleOnWheel, numberFilter, timezonesAll} from '@src/modules/accounts/utils';
import {DateTimePicker} from '@src/modules/accounts/components/DateTimePicker';
import ResponsibleDetails from '@src/modules/accounts/components/ResponsibleDetails';
import AccountResources from '@src/modules/accounts/components/AccountResources';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import {useEditMutation, useUpdateExpiryDateMutation} from '@src/modules/accounts/accounts.api';
import {useCan} from '@src/hooks/useCan';
import * as moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import config from '@src/utils/config';
import {routes} from '@src/utils/routes';
import {useFormik} from 'formik';
import {editTrainingAccountSchema} from '@src/modules/accounts/schema';
import AccountCopyLink from '@src/modules/accounts/components/AccountCopyLink';
import AccountDisabledSwitch from '@src/modules/accounts/components/AccountDisabledSwitch';

import styles from './edit-form.module.scss';

export function EditForm({initialValues, logoUrl}) {
    const [avatarUrl, setAvatarUrl] = useState(logoUrl);

    const can = useCan();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const [
        edit,
        {isLoading},
    ] = useEditMutation();
    const [
        updateExpiryDate,
    ] = useUpdateExpiryDateMutation();

    async function onSubmit(form) {
        try {
            await edit({
                id: `trainings/${id}`,
                body: {
                    name: form.name,
                    subdomain: form.subdomain,
                    max_users: form.mu === '' ? null : form.mu,
                    responsible: {
                        name: form.responsible?.name,
                        email: form.re,
                    },
                    modules: {
                        climateCheck: form.modules?.climateCheck || false,
                    },
                    timezone: form.timezone.value,
                },
            }).unwrap();

            if (initialValues.expiryDate !== formik.values.expiryDate) {
                await updateExpiryDate({id, body: moment(form.expiryDate).toISOString()}).unwrap();
            }

            enqueueSnackbar(t('ACCOUNTS_PAGE.EDIT_SUCCESS'), {variant: 'success'});
        } catch  {
            enqueueSnackbar(t('ACCOUNTS_PAGE.EDIT_ERROR'), {variant: 'error'});
        }
    }

    const formik = useFormik({
        validationSchema: editTrainingAccountSchema(t),
        initialValues,
        enableReinitialize: true,
        onSubmit,
    });

    const permissionResource = permissionResources.trainingAccounts;
    const link = {
        url: 'https://' + config.appDomainMask.replace('*', `${formik.values.subdomain}-demo`),
    };

    return (
        <>
            <LogoUpload
                accountId={`trainings/${id}`}
                defaultAvatar={avatarUrl}
                onReceiveNewImage={setAvatarUrl}
            />

            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
                autoComplete='off'
            >
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.item}>
                            <FormikTextField
                                fullWidth
                                name='name'
                                label={t('ACCOUNTS_PAGE.FORM.NAME')}
                                formik={formik}
                                readOnly={isLoading}
                                disabled={isLoading}
                            />
                        </div>

                        <div  className={styles.item}>
                            <Box sx={{display: 'flex', gap: 2}}>
                                <FormikTextField
                                    fullWidth
                                    name='subdomain'
                                    label={t('ACCOUNTS_PAGE.FORM.SUBDOMAIN')}
                                    formik={formik}
                                    readOnly={isLoading}
                                    disabled={isLoading}
                                    onKeyUp={() => formik.setFieldValue('subdomain', formik.values.subdomain.toLowerCase())}
                                />

                                <Tooltip
                                    title={
                                        <ul>
                                            <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.LOWER_CASE')}</li>
                                            <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPACES')}</li>
                                            <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.HYPHENS')}</li>
                                            <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.SPECIAL_CHARACTER')}</li>
                                            <li>{t('DEMO_ACCOUNTS_PAGE.SUBDOMAIN_RULES.MARKERS')}</li>
                                        </ul>
                                    }
                                >
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>

                            {formik.values.subdomain.length > 0 && (
                                <FormHelperText>
                                    {t('DEMO_ACCOUNTS_PAGE.FORM.SUBDOMAIN_HELPER_TEXT',
                                        {subdomain: `${formik.values.subdomain}-demo`},
                                    )}
                                </FormHelperText>
                            )}
                        </div>
                    </div>

                    <div  className={styles.row}>
                        <div className={styles.item}>
                            <FormikTextField
                                name='mu'
                                label={t('ACCOUNTS_PAGE.MAX_NUMBER_USERS')}
                                formik={formik}
                                readOnly={isLoading}
                                disabled={isLoading}
                                type='number'
                                InputProps={{inputProps: {min: 1}}}
                                onKeyPress={(e) => numberFilter(e)}
                                onWheel={handleOnWheel}
                                fullWidth
                            />
                        </div>

                        <div className={styles.item}>
                            <Autocomplete
                                options={timezonesAll}
                                getOptionLabel={(option) => option.label}
                                disabled={isLoading}
                                value={formik.values.timezone}
                                onChange={(_, value) => {
                                    formik.setFieldValue('timezone', value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={t('ACCOUNTS_PAGE.FORM.TIMEZONE')}
                                        error={Boolean(formik.touched.timezone && formik.errors.timezone)}
                                        helperText={formik.touched.timezone && formik.errors.timezone}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <DateTimePicker
                            value={formik.values.expiryDate}
                            onChange={(date) => formik.setFieldValue('expiryDate', date)}
                            showTimeSelect
                            pickerProps={{
                                disabled: isLoading,
                            }}
                        />
                    </div>

                    <ResponsibleDetails
                        isEdit
                        formik={formik}
                        showPasswordConfirmation={false}
                        readOnly={isLoading}
                        disabled={isLoading}
                    />

                    <div className={styles.row}>
                        <div className={styles.item}>
                            <AccountCopyLink link={link} />
                        </div>
                        <div className={styles.item}>
                            <AccountDisabledSwitch formik={formik} />
                        </div>
                    </div>
                    
                    <AccountResources
                        formik={formik}
                        showLink={true}
                        link={link}
                    />

                    {can(permissionResource, permissionTypes.edit) && (
                        <div className={styles.buttonsContainer}>
                            <Button
                                variant='outlined'
                                disabled={isLoading}
                                onClick={() => navigate(routes.DEMO_ACCOUNTS)}
                            >
                                {t('ACCOUNTS_PAGE.CANCEL')}
                            </Button>

                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                disabled={isLoading || !formik.dirty}
                                variant='contained'
                            >
                                {t('ACCOUNTS_PAGE.SAVE')}
                            </LoadingButton>
                        </div>
                    )}
                </div>
            </form>
        </>
    );
}
